<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tool retry giao dịch thẻ</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Chọn nhà cung cấp (*)">
              <b-form-select
                v-model="card.provider"
                :options="options.provider"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Chọn dịch vụ (*)">
              <b-form-select
                v-model="card.service"
                :options="options.service"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Chọn sản phẩm (*)">
              <b-form-select
                v-model="card.productId"
                :options="options.product"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Nhập mã giao dịch (*)">
              <b-form-input
                v-model="card.orderNumber"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="retryCard()"
              >Lấy thẻ </b-button
            >&nbsp;
          </b-col>
        </b-row>
        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
        </b-table>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      items: [],
      fields: [
        { order_number: "Mã giao dịch" },
        { card_provider: "Nhà cung cấp" },
        { serial: "Mã serial" },
      ],
      card: {
        provider: null,
        service: null,
        productId: null,
        orderNumber: null,
      },
      options: {
        provider: [
          { value: "Epay", text: "EPAY" },
          { value: "coda", text: "CODA" },
          { value: "Imedia", text: "IMEDIA" },
          { value: "funcard", text: "FUNCARD" },
        ],
        service: [],
        product: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tool retry giao dịch thẻ", route: "retry-card" },
    ]);
  },
  methods: {
    retryCard() {
      if (
        this.card.provider === null ||
        this.card.productId === null ||
        this.card.orderNumber === null
      ) {
        this.notifyAlert(
          "warn",
          "Nhà cung cấp, sản phẩm và mã giao dịch không được bỏ trống"
        );
        return;
      }
      this.$bus.$emit("show-loading", true);
      let params = {
        card_provider: this.card.provider,
        order_number: this.card.orderNumber,
        product_id: this.card.productId,
      };
      CmsRepository.retryCard(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert("success", "Lấy thẻ thành công");
            this.items = response.data.data;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$bus.$emit("show-loading", false);
    },
    listService() {
      let params = {
        arrayType: JSON.stringify(["PHONE_CARD", "SERVICE_CARD", "GAME_CARD"]),
      };
      CmsRepository.listServiceCard(params)
        .then((response) => {
          if (response.data.error_code) {
            alert("Lấy dịch vụ thất bại");
          } else {
            this.options.service = response.data.data;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    listProduct() {
      let params = {
        service_id: this.card.service,
      };
      CmsRepository.listProductCard(params)
        .then((response) => {
          if (response.data.error_code) {
            alert("Lấy dịch vụ thất bại");
          } else {
            this.options.product = response.data.data;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
  },

  created() {
    this.listService();
  },
  watch: {
    "card.service"() {
      this.listProduct();
    },
  },
};
</script>
